<template>
  <div class="fixed bottom-0 left-0 right-0 bg-white border-t shadow-lg z-50">
    <div v-if="!processing" class="p-2">
      <!-- Process Actions Bar -->
      <div v-if="useProcessStore().product_list.length > 0" class="flex items-center gap-2 px-2 pb-2 border-b  border-gray-100">
        <reject-process />
        <process-print v-if="useProcessStore().current_step.print_document" />
        <process-print-warranty v-if="useProcessStore().current_step.gives_warranty" :title="'Percaktimi Garancive'" />
        <product-scan-modal v-if="useProcessStore().current_step.ask_serial == 1" />
        <validate-products />
        <process-discount v-if="useProcessStore().current_step.gives_discount == 1" />
      </div>

      <div class="flex justify-end gap-4 pr-3">
        <!-- Disputes Block -->
        <div v-if="isUserAllowedToStep()" class="border-r pr-2 border-gray-100">
          <div v-for="dispute in useProcessStore().current_step.disputes" :key="dispute.guid"
               class="flex flex-col border-b last:border-b-0 py-2">
            <button class="text-left text-sm px-3 py-1 hover:bg-gray-100 rounded-lg transition-colors text-gray-700">
              {{ dispute.name }}
            </button>
            <div class="px-3 space-y-1">
              <div v-if="dispute.description" class="text-xs text-gray-600 flex items-center gap-1">
                <i class="fa-solid fa-circle-info w-4 text-blue-500"></i>
                {{ dispute.description }}
              </div>
              <div v-if="dispute.is_required" class="text-xs text-gray-600 flex items-center gap-1">
                <i class="fa-solid fa-triangle-exclamation w-4 text-amber-500"></i>
                Hap i detyrueshem
              </div>
              <div v-if="dispute.needs_confirmation" class="text-xs text-gray-600 flex items-center gap-1">
                <i class="fa-solid fa-check-to-slot w-4 text-green-500"></i>
                Pret konfirmim
              </div>
            </div>
          </div>
        </div>

        <!-- Payment Block -->
        <div v-if="useProcessStore().current_step.creates_invoices" 
             class="border-r p-2 flex flex-col justify-between">
          <div class="text-xs font-semibold text-gray-700 border-b pb-2">PAGESA</div>
          
          <div class="space-y-2 py-1">
            <div class="flex items-center gap-2">
              <i class="fa fa-credit-card text-gray-500 w-4"></i>
              <select v-model="useProcessStore().process.payment_method"
                      class="text-sm border rounded-lg py-1 px-2 w-40 focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-700">
                <option v-for="method in useProcessStore().payment_methods" 
                        :key="method.guid" 
                        :value="method.guid">
                  {{ method.name }}
                </option>
              </select>
            </div>
            <div class="flex items-center gap-2">
              <i class="fa fa-calendar text-gray-500 w-4"></i>
              <select v-model="useProcessStore().process.payment_term"
                      class="text-sm border rounded-lg py-1 px-2 w-40 focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-700">
                <option v-for="term in useProcessStore().payment_terms" 
                        :key="term.guid" 
                        :value="term.guid">
                  {{ term.name }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <!-- Main Action Block -->
        <div v-if="isUserAllowedToStep()" class="px-2 min-w-[220px] flex flex-col justify-between">
          <!-- Amount Exceed Check -->
          <div v-if="exceed_amount_check" class="flex flex-col gap-2 py-2">
            <div class="flex items-center gap-2">
              <i class="fa-solid fa-triangle-exclamation text-amber-500"></i>
              <input v-model="useProcessStore().new_customer_id" 
                     type="text" 
                     placeholder="Nr. Dokumenti"
                     class="border rounded-lg px-3 py-1 text-sm flex-1 focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-700">
              <button @click.prevent="useProcessStore().save_customer_id()" 
                      class="bg-green-100 hover:bg-green-200 rounded-lg px-3 py-1 transition-colors text-green-700">
                <i class="fa fa-save text-green-600"></i>
              </button>
            </div>
            <div class="text-xs text-gray-600">
              Kur totali e kalon shifren 500.000 Leke, eshte e detyrueshme te vendoset numri i dokumentit te klientit.
            </div>
          </div>

          <div v-else>
            <!-- Pending Discount -->
            <div v-if="useProcessStore().process?.has_pending_discount">
              <button @click.prevent="open_discount_template(useProcessStore().process?.pending_discount.guid)"
                      class="w-full flex items-center gap-2 px-3 py-2 bg-green-50 hover:bg-green-100 border rounded-lg transition-colors text-green-700">
                <i class="fa-solid fa-up-right-from-square text-green-600"></i>
                <span class="text-sm">Kerkese per cmim ne pritje</span>
              </button>
            </div>

            <!-- Complete Action -->
            <div v-else-if="can_be_completed" class="py-2">
              <button @click.prevent="saveDocumentAndContinue()"
                      class="w-full flex items-center gap-2 px-3 py-2 bg-blue-50 hover:bg-blue-100 border rounded-lg transition-colors text-blue-700">
                <i class="fa fa-check text-blue-600"></i>
                <div class="text-sm">
                  <div>PERFUNDO {{ useProcessStore().current_step?.name }}</div>
                  <div v-if="useProcessStore().process?.next_step?.length > 0" 
                       class="text-xs text-gray-600 border-t mt-1 pt-1">
                    vazhdo tek {{ useProcessStore().process.next_step[0].name }}
                    <i class="fa-solid fa-caret-right"></i>
                  </div>
                </div>
              </button>
            </div>

            <!-- Incomplete Action -->
            <div v-else>
              <div class="flex items-center gap-2 px-3 py-2 bg-red-50 border rounded-lg text-red-700">
                <i class="fa fa-exclamation-triangle text-red-600"></i>
                <span class="text-sm">Kompletoni fushat e detyrueshme</span>
              </div>
            </div>

            <!-- Status Indicators -->
            <div v-if="useProcessStore().process?.document_data" 
                 class="flex flex-wrap gap-2 mt-2 text-xs">
              <div v-if="useProcessStore().current_step.creates_invoices"
                   class="bg-blue-50 px-2 py-1 rounded-lg flex items-center gap-1 text-blue-700">
                <i class="fa fa-info text-blue-500"></i>
                Krijon fature
              </div>

              <div v-if="useProcessStore().current_step.is_service == 1"
                   class="bg-gray-50 px-2 py-1 rounded-lg flex items-center gap-1 text-gray-700">
                <input type="checkbox" 
                       v-model="useProcessStore().current_step.skip_this_step" 
                       true-value="1" 
                       false-value="0"
                       class="w-4 h-4 rounded focus:ring-blue-500">
                Sherbimi pranohet
              </div>

              <div v-if="useProcessStore().current_step.is_transaction > 0"
                   class="px-2 py-1 rounded-lg flex items-center gap-1"
                   :class="{'bg-red-50 text-red-700': useProcessStore().current_step.is_transaction == 1, 'bg-green-50 text-green-700': useProcessStore().current_step.is_transaction == 2}">
                <i class="fa fa-dolly" 
                   :class="{'text-red-600': useProcessStore().current_step.is_transaction == 1, 'text-green-600': useProcessStore().current_step.is_transaction == 2}"></i>
                {{ useProcessStore().current_step.is_transaction == 1 ? 'Dalje' : 'Hyrje' }}
              </div>

              <div v-if="useProcessStore().process?.document_data.own_items_not_addressed == 1 && 
                        useProcessStore().process?.document_data.first_step == 0"
                   class="bg-green-50 px-2 py-1 rounded-lg flex items-center gap-1 text-green-700">
                <i class="fa fa-circle text-green-500"></i>
                Ka produkte per adresim
              </div>
            </div>
          </div>
        </div>

        <!-- No Permission Block -->
        <div v-else class="px-4 py-2 bg-red-50 border-l flex items-center gap-2 text-sm rounded-lg text-red-700">
          <i class="fa fa-lock text-red-600"></i>
          <span>Ju nuk keni te drejta tek ky hap i procesit!</span>
        </div>
      </div>
    </div>

    <!-- Loading State -->
    <div v-else class="flex items-center justify-center gap-2 p-3 text-sm text-gray-600">
      <i class="fa fa-spinner fa-spin"></i>
      <span>Duke u procesuar...</span>
    </div>
  </div>
</template>

<script>
import permissionMixin from '@/mixins/permissions-mixin.js'
export default {
  mixins: [permissionMixin],

  methods: {
      open_discount_template(request_id) {
          this.$router.push({ name: 'Konfirmo zbritje proces', params: { id: request_id, is_process: 1 } })
      }
  },

  
}
</script>

<script setup>
import { inject, ref, computed } from 'vue'
import { useProcessStore } from '@/stores/process'
import processPrint from './process_print/index.vue'
import processPrintWarranty from './process_warranty/index.vue'
import productScanModal from '@/pages/processes/components/product_scan_modal.vue'
import rejectProcess from '@/pages/process_new/components/reject_process.vue'
import validateProducts from '@/pages/process_new/components/validate_products.vue'
import processDiscount from '@/pages/process_new/components/process_discount.vue'
const toast = inject('toast')

const processing = ref(false)

// LOGIC: this function saves the document and continues to the next step 
// if the step is the last one it shows a message that the document is completed
async function saveDocumentAndContinue() {
  if (processing.value) {
    return
  }

  // Backup working for 
  if((!useProcessStore().process.payment_method || !useProcessStore().process.payment_term) && useProcessStore().current_step.creates_invoices) {
    toast.error('Ju lutem plotesoni te dhenat e pageses per te vazhduar', {position: 'top'})
    return
  }

  processing.value = true
  await useProcessStore().open_step()
    .then((resp) => {
      if (resp.data.success) {
				toast.success(resp.data.success, {position: 'top'})
      }
      else if (resp.data.error) {
        toast.error(resp.data.error)
      }

      // LOGIC: reload the page after 3 seconds 
      // to get again the document data from the server 
      // and to get the new step data 
      if (window.location.hash.includes('DONT_RELOAD_PAGE')) {
        return
      }
      setTimeout(() => {
        window.location.reload()
      }, 3000)

      // TODO: alternative solution should be instead of reloading the page 
    })
}

function isUserAllowedToStep() {
  // CHECK: if the user is allowed to the step
  return JSON.parse(localStorage.user_steps).includes(useProcessStore()?.current_step.guid)
}

// products_with_no_serials computed property 
var products_with_no_serials = computed(() => {
  return useProcessStore().product_list.filter(p => p.serial_number == null || p.serial_number == '')
})

// can_be_completed computed property 
var can_be_completed = computed(() => {
  if(useProcessStore().current_step.ask_serial == 1 && products_with_no_serials.value.length > 0) {
    return false
  }
  return true
})

// exceed_amount_check computed property
var exceed_amount_check = computed(() => {
  if(
    useProcessStore().process.destination_type == 4 && 
    useProcessStore().document_overall_total > 50000 && 
    !useProcessStore().process.destination_is_business && 
    !useProcessStore().process.document_data.destination_personal_id 
  ) return true
  // else if(
  //   useProcessStore().process.destination_type == 4 && 
  // )
  return false
})

</script>

<style>
.step-disputes-wrap {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.footer-pre-wrap {
  position: sticky;
  right: 0px;
  bottom: 0px;
  width: 100%;
  margin-left: auto;
  display: flex;

}
</style>
