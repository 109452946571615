<template>
    <div class=" is-flex w-full mb-4" style="">
        <span class="font-lg uppercase mr-3">
            Total
        </span>
        <div class="is-flex is-flex-direction-column px-2 bg-white is-flex-grow-1">
            <!-- <div v-if="useProcessStore().price_filter(useProcessStore().document_services_total)" class="is-flex is-justify-content-space-between " >
                <span class="is-size-7 is-uppercase text-slate-400"> Totali i produkteve: </span>
                <span class="is-size-7"> {{useProcessStore().price_filter(useProcessStore().document_product_total)}} </span>
            </div> -->
            <div class="is-flex is-flex-direction-column ">
                <div class=" p-1 text-sm bg-slate-100">
                    Produkte
                </div>
                <div class="is-flex  px-1 pb-1 pt-0 is-flex-direction-column ">
                    <div class="is-flex is-justify-content-space-between border-b is-align-items-flex-end">
                        <span class="text-xs w-1/2 text-right">Tot. produkte</span>
                        <!-- <span class="font-semibold text-sm"> {{useProcessStore().price_filter(useProcessStore().document_product_total)}}</span> -->
                        <span class="font-semibold text-sm"> {{useProcessStore().price_filter(useProcessStore().totals.with_vat)}}</span>
                    </div>
                    <div class="is-flex is-justify-content-space-between border-b is-align-items-flex-end">
                        <span class="text-xs w-1/2 text-right">Pa TVSH</span>
                        <span class=" text-sm">{{useProcessStore().price_filter(useProcessStore().totals.no_vat)}}</span>
                    </div>
                    <div class="is-flex is-justify-content-space-between  is-align-items-flex-end">
                        <span class="text-xs w-1/2 text-right">TVSH</span>
                        <span class=" text-sm">{{useProcessStore().price_filter(useProcessStore().totals.vat)}}</span>
                    </div>
                </div>
            </div>
            <div class="is-flex is-flex-direction-column mt-2">
                <div class=" p-1 text-sm bg-slate-100">
                    Sherbime
                </div>
                <div class="is-flex  px-1 pb-1 pt-0 is-flex-direction-column ">
                    <div class="is-flex is-justify-content-space-between  is-align-items-flex-end">
                        <span class="text-xs w-1/2 text-right">Tot. sherbime</span>
                        <span class="font-semibold text-sm"> {{useProcessStore().price_filter(useProcessStore().document_services_total)}}</span>
                    </div>
                </div>
            </div>
            <div class="is-flex is-flex-direction-column mt-2">
                <div class=" p-1 text-sm bg-slate-100">
                    Sherbime ekstra
                </div>
                <div class="is-flex  px-1 pb-1 pt-0 is-flex-direction-column ">
                    <div v-for="(service, indexaa) in useProcessStore().service_list" :key="indexaa"
                         class="is-flex is-justify-content-space-between  is-align-items-flex-end">
                        <span class="text-xs w-1/2 text-right">{{service.name}}</span>
                        <span class="font-semibold text-sm"> {{useProcessStore().price_filter(
                            service.price)}}
                        </span>
                    </div>
                </div>
            </div>
            <div v-if="useProcessStore().airbag_total" class="is-flex is-flex-direction-column mt-2">
                <div class=" p-1 text-sm bg-slate-100">
                    Garanci airbag
                </div>
                <div class="is-flex  px-1 pb-1 pt-0 is-flex-direction-column ">
                    <div class="is-flex is-justify-content-space-between  is-align-items-flex-end">
                        <span class="text-xs w-1/2 text-right">Tot. garanci airbag</span>
                        <span class="font-semibold text-sm"> {{useProcessStore().price_filter(useProcessStore().airbag_total)}}</span>
                    </div>
                </div>
            </div>
            <!-- <div v-if="useProcessStore().price_filter(useProcessStore().document_services_total)" class="is-flex is-justify-content-space-between">
                <span class="is-size-7 is-uppercase text-slate-400"> Totali i sherbimeve: </span>
                <span class="is-size-7"> {{useProcessStore().price_filter(useProcessStore().document_services_total)}} </span>
            </div> -->
            <div class="is-flex is-justify-content-space-between mt-2 border-t border-black">
                <span class="font-semibold"> Total </span>
                <span class="font-semibold"> {{useProcessStore().price_filter(useProcessStore().document_overall_total)}} </span>
            </div>

						<!-- show paid amount input and exchange to be returned if there'a any --> 
						<div class="is-flex is-justify-content-space-between mt-2 border-t border-black"> 
							<span class="font-semibold"> Paguar </span> 
							<input type="text" class="font-semibold text-right w-1/2 ml-auto mr-1" :value="formatNumber(useProcessStore().document_paid_amount)" @input="handleInput" />
							Lek	
						</div>
						<div class="is-flex is-justify-content-space-between mt-2 border-t border-black" v-if="differencePaidAndTotal != 0" style="background: #fff3ba"> 
							<span class="font-semibold">
								<span v-if="differencePaidAndTotal < 0">Kusur</span> 
								<span v-else-if="differencePaidAndTotal > 0">Mungojne</span> 
							</span> 
							<span class="font-semibold"> {{useProcessStore().price_filter(Math.abs(useProcessStore().document_overall_total - (useProcessStore().document_paid_amount || useProcessStore().document_overall_total)))}} </span>
						</div>
						

        </div>
    </div>
  
</template>

<script setup>
	import { computed } from 'vue'
  import { useProcessStore } from '@/stores/process'
	
	const differencePaidAndTotal = computed(() => {
		return useProcessStore().document_overall_total - (useProcessStore().document_paid_amount || useProcessStore().document_overall_total)
	})
const formatNumber = (value) => {
  return value?.toLocaleString('en-US') || ''
}

const handleInput = (event) => {
  const value = event.target.value.replace(/,/g, '')
  useProcessStore().document_paid_amount = Number(value)
}
</script>

<style>

</style>
